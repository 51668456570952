const RoutesConfig = {
  home: '/',
  tinHay: '/',
  search: '/tim-kiem',
  category: {
    kienTruc: '/category/kien-truc',
    kienTrucXaHoi: '/category/kien-truc/kien-truc-xa-hoi',
    truonghoc: '/category/kien-truc/kien-truc-xa-hoi/truong-hoc',
    nhaDep: '/category/nha-dep',
  },
  account: {
    index: '/account',
    signIn: '/account?tab=sign-in',
    signUp: '/account?tab=sign-up',
    verify: '/account/verify',
    resetPassword: '/account/reset-password',
    success: '/account/success',
  },
  quote: '/bang-gia',
  advertisement: '/quang-cao',
  contact: '/lien-he',
  privacy: '/dieu-khoan',
  user: {
    index: 'thong-tin',
    savedPosts: 'tin-da-luu',
    comment: 'hoat-dong-binh-luan',
    history: 'lich-su-giao-dich',
    coupon: 'khuyen-mai',
  },
};

export default RoutesConfig;
