import logo from '@/assets/images/logo.png';
import Image from 'next/image';
import { AccountIcon, Hamburger, HomeIcon, SearchIcon } from '@/assets/icons';
import Link from 'next/link';
import { Drawer, Input } from 'antd';
import { useUser } from '@/contexts/AuthContext';
import RoutesConfig from '@/constants/url';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useGetCategoriesHeaderQuery,
  useGetUserByAwsUserNameQuery,
} from '@/generated/graphql';
import { GetCategoriesHeader } from '@/constants/defaultParams';
import useGetSeoConfig from '@/hooks/useGetSeoConfig';
import { useAmp } from 'next/amp';
// import { SwitchLangugage } from '../Shared';

const Header = () => {
  const isAmp = useAmp();
  const { data, loading } = useGetCategoriesHeaderQuery({
    variables: GetCategoriesHeader,
  });
  const { raovatUrl } = useGetSeoConfig();
  const router = useRouter();

  const [showSearchBar, setShowSearchBar] = useState(false);

  const { user } = useUser();

  const { data: DataUser, refetch } = useGetUserByAwsUserNameQuery({
    variables: { userId: user?.userId },
  });

  useEffect(() => {
    refetch();
  }, [user, refetch]);

  const [isScrolled, setIsScrolled] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > prevScrollY.current);
      prevScrollY.current = currentScrollY;
    };

    window?.addEventListener('scroll', handleScroll);

    return () => {
      window?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearch = (value: string) => {
    if (value) {
      router.push({ pathname: RoutesConfig.search, query: { keyword: value } });
    }
    setShowSearchBar(false);
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {isAmp ? (
        <div
          className="amp-font-inter"
          style={{
            position: 'sticky',
            top: '0',
            zIndex: '50',
            background: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '8px 16px',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: '<button on="tap:sidebar.open">☰</button>',
              }}
            />
            <a href={RoutesConfig.home} style={{}}>
              <amp-img
                src="https://s3.ap-southeast-1.amazonaws.com/skydev-general.dev/logo-tinhay.png"
                alt={'logo'}
                width="100"
                height="40"
                layout="fixed"
              ></amp-img>
            </a>

            <a
              href={
                user
                  ? `user/${RoutesConfig.user.index}`
                  : RoutesConfig.account.index
              }
              style={{}}
            >
              <AccountIcon />
              <p style={{ fontSize: '12px' }}>
                {DataUser?.users?.[0]?.fullName ?? DataUser?.users?.[0]?.email}
              </p>
            </a>
          </div>

          <div style={{ background: '#006097' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '24px',
                overflowX: 'auto',
              }}
            >
              <a href={RoutesConfig.home}>
                <HomeIcon />
              </a>
              {data?.categories?.map((item, index) => (
                <a
                  key={index}
                  href={`/${item?.slug}`}
                  style={{
                    whiteSpace: 'nowrap',
                    padding: '16px 0',
                    color: 'white',
                    textTransform: 'uppercase',
                    fontWeight: '500',
                  }}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>

          <amp-sidebar
            id="sidebar"
            layout="nodisplay"
            style={{ width: '300px', background: 'white', zIndex: '50' }}
          >
            <div
              style={{
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: '<button on="tap:sidebar.close">X</button>',
                }}
              />
              <div>
                <a
                  href={RoutesConfig.advertisement}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: 'black',
                    textTransform: 'uppercase',
                  }}
                >
                  Quảng cáo
                </a>
              </div>
              <div>
                <a
                  href={raovatUrl}
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: 'black',
                    textTransform: 'uppercase',
                  }}
                >
                  Rao vặt
                </a>
              </div>
              <div>
                <a
                  href={RoutesConfig.quote}
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: 'white',
                    textTransform: 'uppercase',
                    background: '#006097',
                    borderRadius: '6px',
                    padding: '12px 40px',
                    textAlign: 'center',
                  }}
                >
                  Đăng ký nhận tin
                </a>
              </div>
            </div>
          </amp-sidebar>
        </div>
      ) : (
        <div
          className={`sticky top-0 z-50 duration-500 bg-white ${
            isScrolled
              ? '-translate-y-[56px] lg:-translate-y-[68px]'
              : 'translate-y-0'
          } `}
        >
          <div className="container flex items-center justify-between py-2 ">
            <div className="lg:hidden" onClick={() => setOpen(true)}>
              <Hamburger />
            </div>

            <Link
              href={RoutesConfig.home}
              className="lg:w-[122px] lg:h-[50px] relative w-[100px] h-[40px]"
            >
              <Image
                src={logo}
                alt={'logo'}
                fill
                priority
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </Link>
            <div className="lg:flex items-center gap-9 hidden">
              <Link
                href={RoutesConfig.advertisement}
                className="text-xs font-medium cursor-pointer hover:scale-110 duration-300"
              >
                QUẢNG CÁO
              </Link>
              |
              <Link
                href={raovatUrl}
                className="text-xs font-medium cursor-pointer hover:scale-110 duration-300"
              >
                RAO VẶT
              </Link>
              |
              {!showSearchBar ? (
                <SearchIcon
                  onClick={() => setShowSearchBar(true)}
                  className="cursor-pointer hover:scale-110 duration-300"
                />
              ) : (
                <>
                  <Input.Search
                    placeholder="Tìm kiếm ..."
                    onSearch={value => handleSearch(value)}
                    style={{ width: 300 }}
                    allowClear
                    className="flex-1 h-10"
                    enterButton={<SearchIcon />}
                  />
                </>
              )}
              <Link
                aria-label="account"
                className="flex gap-2 items-center hover:scale-110 duration-300 rounded-full hover:bg-primary/10"
                href={
                  user
                    ? `user/${RoutesConfig.user.index}`
                    : RoutesConfig.account.index
                }
              >
                <AccountIcon />
                {DataUser?.users?.[0]?.fullName ?? DataUser?.users?.[0]?.email}
              </Link>
              {/* <SwitchLangugage /> */}
              <button
                onClick={() => {
                  router.push(RoutesConfig.quote);
                }}
                className="bg-primary text-white rounded-md px-10 py-3 text-xs h-fit font-medium"
              >
                ĐĂNG KÝ NHẬN TIN
              </button>
            </div>
            <Link
              aria-label="account"
              className="flex lg:hidden gap-2 items-center hover:scale-110 duration-300 rounded-full hover:bg-primary/10"
              href={
                user
                  ? `user/${RoutesConfig.user.index}`
                  : RoutesConfig.account.index
              }
            >
              <AccountIcon />
              <div className="text-[12px]">
                {DataUser?.users?.[0]?.fullName ?? DataUser?.users?.[0]?.email}
              </div>
            </Link>
          </div>
          <div className="bg-primary ">
            <div className="container flex overflow-x-auto no-scrollbar items-center justify-between  gap-6 ">
              <Link
                aria-label="home"
                href={RoutesConfig.home}
                className="hover:scale-110 duration-300"
              >
                <HomeIcon />
              </Link>
              {data?.categories?.map((item, index) => (
                <Link
                  key={index}
                  href={`/${item?.slug}`}
                  className={`${
                    item?.slug === router.asPath.replace('/', '')
                      ? 'border-white'
                      : 'border-transparent'
                  } border-b-2 py-4  transition-all text-[15px] whitespace-nowrap relative font-medium text-white uppercase hover:opacity-50 duration-300`}
                >
                  {item.name}
                </Link>
              ))}
              {loading &&
                Array.from({ length: 10 }).map((_, index) => (
                  <div
                    className="w-full animate-pulse bg-white/50 h-5 rounded-md"
                    key={index}
                  ></div>
                ))}
            </div>
          </div>
          <Drawer
            placement="left"
            open={open}
            width={'350px'}
            onClose={onClose}
          >
            <div className="flex flex-col gap-5">
              <Input.Search
                placeholder="Tìm kiếm ..."
                onSearch={value => handleSearch(value)}
                style={{ width: 300 }}
                allowClear
                className="flex-1 h-10"
                enterButton={<SearchIcon />}
              />
              <Link
                href={RoutesConfig.advertisement}
                className="text-xs font-bold text-black cursor-pointer hover:scale-110 duration-300"
              >
                QUẢNG CÁO
              </Link>
              <div
                // href={RoutesConfig.quote}
                onClick={() => {
                  router.push(raovatUrl);
                  onClose();
                }}
                className="text-xs font-bold text-black cursor-pointer hover:scale-110 duration-300"
              >
                RAO VẶT
              </div>
              <button
                onClick={() => {
                  router.push(RoutesConfig.quote);
                  onClose();
                }}
                className="bg-primary text-white rounded-md px-10 py-3 text-xs h-fit font-medium"
              >
                ĐĂNG KÝ NHẬN TIN
              </button>
              {/* <SwitchLangugage /> */}
            </div>
          </Drawer>
        </div>
      )}
    </>
  );
};

export default Header;
